//import Methods from "@/components/methods/methods.vue";
//import postMethods from '@/services/methods/postMethods';
import MethodsAPI from "@/services/methods/httpMethods";
import axios from "axios";




export default {
  name: "methods",
  data() {
    return {
      searchMethods: '',

      pgNumb: '',

      // about pages
      totalPages: '',
      currentPage: 1,
      itensQtd: '',

      // body methods
      methodsAddItens: {
        IntegracaoId: "",
        GotasMetodos: "",
        DescricaoMetodo: "",
        Percentual: "",
      },
      allItens: [],

      // receive methods listItens
      itensMethods: [],
    };
  },
  components: {
    //  Methods,
  },

  methods: {
    previousPage(page) {
      if (page === this.currentPage && (page <= this.totalPages && page > 1)) {
        this.currentPage -= 1
        this.listItens()
        return
      }

      this.currentPage = page;
      this.listItens()
    },
    nextPage(page) {
      if (page === this.currentPage && (page < this.totalPages && page >= 1)) {
        this.currentPage += 1
        this.listItens()
        return
      }

      this.currentPage = page;
      this.listItens()
    },

    //Get list Mehtods and pages from Api
    listItens() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/metodo/obter-grid/${this.currentPage}`)
        .then(res => {
          this.itensMethods = res.data.itens;
          this.totalPages = res.data.totalDePaginas;
          this.currentPage = res.data.pagina;
          this.itensQtd = res.data.total;


        });
    },

    //Send itens values to api - button Salvar
    saveItens() {
      MethodsAPI.MethodAddNew(this.methodsAddItens)
        .then(res => {
          alert("salvo com sucesso", res),
            this.listItens(); // update item on screen 
          this.methodsAddItens = {}; //clear fields
        });
    },

    //To edit values itens - button Editar
    editAction(methodsAddItens) {
      this.methodsAddItens = methodsAddItens
    },

    // Send edited values to APi
    updateItens() {
      MethodsAPI.MethodUpdate(this.methodsAddItens)
        .then(res => {
          alert("alterado com sucesso", res),
            this.listItens(); // update item on screen 
          this.methodsAddItens = {}; //clear
        });
    },

    // Delet Itens from Api - button Apagar
    deletItens(methodsAddItens) {
      if (confirm('Deseja excluir?')) {
        axios.post(
          `${process.env.VUE_APP_BASE_URL}/metodo/remover/` + methodsAddItens.id,
        )
          .then((res) => {
            alert("Removido com sucesso", res),
              this.listItens();
            this.errors = []
          })
      }
    },


    // Filter
    allDescrition() {
      let i = 1
      let allPages = '3'
      let arr = []

      //console.log('Total pages', allPages)   

      for (i = 1; i <= allPages; i++) {
        arr.push(axios.get(`${process.env.VUE_APP_BASE_URL}/metodo/obter-grid/` + i))
        Promise.all(arr).then((res) => {
          arr = res

          console.log('Todos itens', arr)
          // console.table(okk);
        })
      }
    },

  },//End Methods

  mounted() {
    //Start with itens list
    this.listItens();
    this.$store.dispatch('methods/methodsSearch')

    //this.allDescrition();

    // console.log('Todos itens',  this.allItens)    
  },

  computed: {
    //Function filter Search
    itensFiltered() {
      let values = []
      values = this.itensMethods.filter((description) => {
        return description.descricaoMetodo.toLowerCase().indexOf(this.searchMethods.toLowerCase()) > -1
      });
      return values;
    },

    itensFilMethods() {
      const { methods } = this.$store.state.methods
      let valuesMe = []
      valuesMe = methods.filter((description) => {
        return description.descricaoMetodo.toLowerCase().indexOf(this.searchMethods.toLowerCase()) > -1
      });
      return valuesMe;
    },

    // renderItens(){
    // const { methods } = this.$store.state.methods
    //  console.log('methods', methods)
    //   return methods;
    // }
  },
};



