import axios from "axios";


const keyUser = localStorage.getItem("userKey");
const tokenUser = JSON.parse(keyUser);

axios.defaults.headers.common["Authorization"] = `Bearer ${tokenUser}`;


  export default{

      MethodAddNew:(methodsItens) => {
        return  axios.post(
          `${process.env.VUE_APP_BASE_URL}/metodo/novo`,methodsItens)
      },
      
      
      MethodUpdate:(methodsItens) => {
        return  axios.post(
          `${process.env.VUE_APP_BASE_URL}/metodo/editar`,methodsItens)
            
      },

     

  }


//https://hapi-farmafacil.prismafive.com.br/swagger/index.html
 